.title {
    font-weight: 700;
}

.body {
    font-weight: 400;
    margin-top: 20px;
}

.actions {
    margin-top: 20px;
}

.actions__items {
    display: flex;
    align-items:center;
    justify-content: space-around;
    margin-top: 20px;
}

.emotions {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.emotions h4{
    margin-bottom: 10px;
}

.attachment{
    text-align: center;
}

.attachment img{
    object-fit: contain;
    max-width: 500px;
    max-height: 500px;
}

.divider {
    margin: 10px 0px 10px 0px;
}

.date-and-location{
    display: flex;
}