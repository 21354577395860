.layout{
    display: flex;
    flex-basis: 0;
    justify-content: center;
    height: 100%;
    align-content: center;
}

.form-section {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.banner-section {
    background-image: url('../../images/lockscreen_8.jpg');
    background-position: center;
    height: 100%;
    width: 100%;
}