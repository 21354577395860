.container {
    margin: auto;
    width: 99%;
    padding: 10px;
}

.menu {
    list-style: none;
    margin-top: 30px;
    width: 100%;
}

.menu__item {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
}

.btn-content {
    display: flex;
    align-content: space-between;
    justify-content: center;
}