*,
*:before,
*:after{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Jost', sans-serif;
}

html{
  height: 100%;
}
body {
  height: 100%;
}

#root {
  height: 100%;
}

.bg-black {
 background-color: #080710;
}

.bg-image {
  background: url('./images/space-background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}