.link {
    text-decoration: none;
}

.title-section {
    margin-top: 1em;
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.title {
    max-height: 2em;
    overflow: hidden;
}
